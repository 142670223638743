import {
    Box,
    CircularProgress,
    ThemeProvider
} from "@mui/material";
import React, {useEffect, useState} from "react";
import SceneListDisplayPage from "./SceneListDisplayPage";
import TeamListDisplayPage from "./TeamListDisplayPage";

import {useParams} from "react-router-dom";
import {theme} from "../themes/customTheme";

import './styles/ProjectPage.scss';
import {useDispatch, useSelector} from "react-redux";
import {
    selectCurrentProject,
    selectProjectNavigationIndex,
    selectUnity,
    setProjectId, setProjectNavigationIndex,
    setCurrentView, setPrimaryNavigationIndex,
} from "../features/states/ProjectFunctions";

import {useNavigate} from "react-router-dom";
import ErrorModal from "../components/Structural Components/ErrorModal";

import {TabPanel} from "../components/Structural Components/TabPanel";
import {GetProjectInfoFromServer, GetTeamInfoFromServer} from "../functions/ServerCallFunctions";
import Typography from "@mui/material/Typography";
import ProjectSettingsPage from "./ProjectSettingsPage";
import {updateBackgroundColour} from "../functions/UniversalJavascriptFunctions";

function ProjectPage() {
    let {id} = useParams();
    const currentProject = useSelector(selectCurrentProject);
    const currentProjectID = id;

    const [projectSwitching, setProjectSwitching] = useState(true);
    const [errorModalSettings, setErrorModalSettings] = useState({open: false, title: "", content: ""});

    const [dateCreated, setDateCreated] = useState("");
    const [dateModified, setDateModified] = useState("");

    const tabValue = useSelector(selectProjectNavigationIndex)
    let isUnity = useSelector(selectUnity);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setCurrentView("projects"));
        dispatch(setPrimaryNavigationIndex(1));
    });

    useEffect(() => {
        console.log("Project Page Mounting");
        updateBackgroundColour(`#404040`)
        return () => {
            dispatch(setCurrentView(""));
        }
    }, []);

    useEffect(() => () => {
        console.log("Project Page Unmounting");
        updateBackgroundColour("#7F7F7F")
    }, [])


    useEffect(() => {
        if (currentProject !== null && currentProjectID === id) {
            setProjectSwitching(false);
            webReturnProjectInfo(id);
        } else {
            setProjectSwitching(true);
            dispatch(setProjectId(id));
            dispatch(setProjectNavigationIndex(0));
            if (isUnity) {
                webReturnProjectInfo(id);
            }
        }
    }, [id]);

    function webReturnProjectInfo(projectId) {
        if (window.vuplex) {
            const params = {
                type: 'projectInfo',
                value: projectId,
            }
            window.vuplex.postMessage(params)
        }
    }

    function redirectToProjects() {
        navigate('/projects');
    }

    useEffect(() => {
        if (projectSwitching === true) {
            SetupProjectInfo();
        }
    }, [id]);

    useEffect(() => {
        GetProjectInfo();
    }, [currentProject])

    async function SetupProjectInfo() {
        try {
            await GetProjectInfoFromServer(id)
        } catch (e) {
            handleOpenErrorModal("Error", "The project you are trying to access does not exist or you do not have permission to access it. \n If you believe you should have access, please ensure you are logged in to the correct account and try again.");
        }
    }

    async function GetProjectInfo() {
        if (currentProject !== null) {
            await GetTeamInfoFromServer(id)
            await setProjectSwitching(false);
            setDateCreated(new Date(currentProject.createdAt).toLocaleDateString() + " " + new Date(currentProject.createdAt).toLocaleTimeString());
            setDateModified(new Date(currentProject.updatedAt).toLocaleDateString() + " " + new Date(currentProject.updatedAt).toLocaleTimeString());
        }
    }

    function handleOpenErrorModal(title, content) {
        setErrorModalSettings({
            title: title,
            content: content,
            open: true,
        });
    }

    function ProjectName() {
        return (<Typography fontSize={"16px"} variant="h6" component="h2" textTransform={"uppercase"} marginY={1}>
                PROJECT: {currentProject.projectName}
            </Typography>
        )
    }

    function MainProjectArea() {
        if (!projectSwitching && currentProject !== null) {
            return <>
                <ProjectName/>
                <ProjectSettingsPage projectId={id} pro={currentProject.isPro} region={currentProject.region}/>
                <TabPanel value={tabValue} index={0}>
                    <SceneListDisplayPage/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <TeamListDisplayPage projectID={id}/>
                </TabPanel>
            </>
        } else {
            return <CircularProgress/>
        }
    }

    function ProjectDetails() {
        return <Box sx={{textAlign:"end"}}>
            <Typography variant="overline">
                Project Details
            </Typography>
            <Typography variant="body1">
                Project Created: {dateCreated}
            </Typography>
            <Typography variant="body1">
                Project Modified: {dateModified}
            </Typography>
        </Box>
    }

    return <Box className="full-width-blocker projectInfo">
        <Box className={"ProjectPage"}>
            <ThemeProvider theme={theme}>
                <Box className={"inner-box"}>
                    <MainProjectArea/>
                    <ErrorModal settings={errorModalSettings} closeCallback={redirectToProjects}/>
                    <ProjectDetails/>
                </Box>
            </ThemeProvider>
        </Box>
    </Box>
}

export default ProjectPage;