import {Box, Button, CircularProgress, Stack, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectLoginToken, setCurrentView} from "../features/states/ProjectFunctions";
import Typography from "@mui/material/Typography";
import {CopyTextToClipboardIcon} from "../functions/CopyToClipboard";
import UpgradeToProButton from "../components/Structural Components/UpgradeComponents";
import {useForm} from "react-hook-form";
import {Auth} from "aws-amplify";
import React, {useEffect, useState} from "react";
import {GetSubscriptionInfo, GetUserInfoFromServer} from "../functions/ServerCallFunctions";
import Posts from "../api-calls/Posts";
import {AnimotiveSwitch} from "../components/StyledComponents/StyledComponents";
import {SignOutOfClient} from "../functions/AmplifyFunctions";
import {useNavigate} from "react-router-dom";
import {updateBackgroundColour} from "../functions/UniversalJavascriptFunctions";

function DisplayUID({userInfo}) {
    return (<Stack className={"list-card-container"} direction={"column"}>
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>Unique ID:</Typography>
            <Typography>{userInfo.displayId}</Typography>
        </Stack>
        <Stack alignItems={"flex-end"}>
            <CopyTextToClipboardIcon text={userInfo.displayId}/>
        </Stack>
    </Stack>)
}

async function RefreshUserInfo(){
    const response = await GetUserInfoFromServer()
    if(response.code === 200){
        window.location.reload()
    }
}

function LogoutPanel(){
    let navigate = useNavigate();

    async function signOutChain() {
        await SignOutOfClient();
        navigate(`/`);
    }

    return (<Stack direction={"column"} className={"list-card-container"}>
            <Button
                variant={"contained"}
                color={"secondary"}
                onClick={signOutChain}
            >Log Out</Button>
        </Stack>
    )
}



function DisplayAccountType({isPro, userTier}) {

    const Paddle = window.Paddle
    const [paddleSubscriptionInfo, setPaddleSubscriptionInfo] = useState()

    useEffect(() => {
        let vendorID = parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID)
            if (Paddle !== undefined) {
                Paddle.Setup({
                    vendor: vendorID
                });
            }
        },
        [Paddle])

    function OpenPaddleOverlay(paddleURL){
        Paddle.Checkout.open({
            override: paddleURL,
            successCallback: function() {
                RefreshUserInfo()
            },
        });
    }

    async function getPaddleURLs() {
        try {
            const response = await GetSubscriptionInfo()
            setPaddleSubscriptionInfo(response.response[0])
        } catch (e) {
            await GetUserInfoFromServer()
        }
    }

    useEffect(() => {
        if(isPro){
            getPaddleURLs()
        }
    }, [isPro])

    function GetLabel() {
        let label = "Free"

        switch (userTier) {
            case 1:
                label = "Indie Pro"
                break
            case 2:
                label = "Pro"
                break
            case 3:
                label = "Educational Pro"
                break
        }

        return <Stack direction={"column"} alignItems={"flex-end"} spacing={2}>
            <Typography>{label}</Typography>
        </Stack>
    }

    function SubscriptionManagement() {

        if(isPro && paddleSubscriptionInfo === undefined) {
            return <CircularProgress/>
        }

        if (isPro && paddleSubscriptionInfo !== undefined) {
            return <Stack direction={"column"} alignItems={"flex-end"} spacing={2}>
                <Stack direction={"column"} justifyContent={"flex-end"} alignItems={"flex-end"} spacing={2}>
                    <Typography>Expires: {paddleSubscriptionInfo.next_payment.date}</Typography>
                    <Typography textTransform={"capitalize"}>Payment Method: {paddleSubscriptionInfo.payment_information.card_type}</Typography>
                    <Typography textTransform={"capitalize"}>State: {paddleSubscriptionInfo.state}</Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => OpenPaddleOverlay(paddleSubscriptionInfo.update_url)}
                    >
                        Update Payment
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => OpenPaddleOverlay(paddleSubscriptionInfo.cancel_url)}
                    >
                        Cancel Renewal
                    </Button>
                </Stack>
            </Stack>

        } else {
            return <UpgradeToProButton/>
        }
    }

    return (<Stack className={"list-card-container"} direction={"column"} spacing={2}>
        <Stack justifyContent={"space-between"} direction={"row"}>
            <Typography>Account Type:</Typography>
            <GetLabel/>
        </Stack>
        <SubscriptionManagement/>
    </Stack>)
}

function DisplayStorageUsed({storageUsedInBytes,availableStorage}) {

    function convertBytesToGigaBytes() {
        return (storageUsedInBytes / 1000000000).toFixed(2)
    }

    return (<Stack className={"list-card-container"} direction={"column"}>
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>Storage Used:</Typography>
            <Typography>{convertBytesToGigaBytes()} GB/{availableStorage} GB</Typography>
        </Stack>
    </Stack>)
}

function ChangePassword() {
    const {register, handleSubmit, formState: {errors}} = useForm();

    const [error, setError] = useState(null)
    const [open, setOpen] = useState(false)

    const onSubmit = (data) => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, data.oldPassword, data.newPassword);
            })
            .then(data => console.log(data))
            .catch(err => setError(err));
    }

    const onError = (errors, e) => console.log(errors, e);

    function DisplayError() {
        if (error) {
            return <Typography color={"red"}>{error.toString()}</Typography>
        }
    }

    function ChangePasswordForm(){
        if(!open){
            return null
        }
        return <Box component={"form"} onSubmit={handleSubmit(onSubmit, onError)}>
            <TextField
                label="Old Password"
                variant="outlined"
                type={"password"}
                {...register("oldPassword", {required: true})}
            />
            <TextField
                label="New Password"
                variant="outlined"
                type={"password"}
                {...register("newPassword", {required: true})}
            />
            <Typography color={"red"}>{errors.oldPassword && "Old Password is required"}</Typography>
            <Typography color={"red"}>{errors.newPassword && "New Password is required"}</Typography>
            <DisplayError/>
            <Button
                variant={"contained"}
                color={"secondary"}
                type={"submit"}
            >Change Password</Button>
        </Box>
    }

    function GetButtonLabel(){
        if(open){
            return "Cancel"
        }else{
            return "Change Password"
        }
    }

    return (<Stack direction={"column"} className={"list-card-container"}>
            <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => setOpen(!open)}
            >{GetButtonLabel()}</Button>
            <ChangePasswordForm/>
        </Stack>
    )
}

function DisplayRetinizeDebug({userInfo}) {

    async function UpdateToPro(){
        const route = "User/update-user-to-pro"
        const body = {
            "isPro": !userInfo.isPro
        }
        const response = await Posts(body, route)
        if(response.code === 200){
            RefreshUserInfo()
        }
    }

    if(userInfo.emailAddress.endsWith("@retinize.com") || userInfo.emailAddress.endsWith("@animotive.com")) {
        return <Stack className={"list-card-container"} direction={"column"}>
            <Stack direction={"column"} justifyContent={"space-between"} spacing={2}>
                <Typography>Retinize Debug:</Typography>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={UpdateToPro}
                >Switch Pro Status</Button>

            </Stack>
        </Stack>
    }
}

export function DeleteAccount() {
    return (<Stack direction={"column"} className={"list-card-container"}>
        <Button
            variant={"contained"}
            color={"error"}
            onClick={() => {
                alert("I'm sorry, Dave. I'm afraid I can't do that. (This feature is not yet implemented.)")
            }}
        >Delete Account</Button>
    </Stack>)
}

function DataCollectionToggle({dataCollectionConsent}){

    const [dataCollectionConsentState, setDataCollectionConsentState] = useState(dataCollectionConsent)

    async function UpdateServerInformation(value) {

        const route = "User/update-data-collection-consent"
        const body = {
            "hasDataCollectionConsent": value
        }
        const response = await Posts(body, route)
        if(response.code === 200){
            console.log("response code 200")
            RefreshUserInfo()
        }
    }

    function DataCollectionToggle(){
        const handleChange = (event) => {
            setDataCollectionConsentState(event.target.checked)
            UpdateServerInformation(event.target.checked)
        }

        return <Stack direction={"row"} alignItems={"center"}>
            <Typography>Off</Typography>
            <AnimotiveSwitch
                sx={{marginX: 1}}
                checked={dataCollectionConsentState}
                onChange={handleChange}
            />
            <Typography>On</Typography>
        </Stack>
    }
    return (<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} className={"list-card-container"}>
        <Typography>Analytics Data Collection:</Typography>
        <DataCollectionToggle/>
    </Stack>)
}

export default function UserAccountSettings() {

    const userInfo = useSelector(selectLoginToken)
    const dispatch = useDispatch()

    useEffect(() => {
        GetUserInfoFromServer()
        dispatch(setCurrentView("Account Settings"))
    }, [dispatch])

    useEffect(() => {
        updateBackgroundColour(`#4f4f4f`)
        return () => {
            dispatch(setCurrentView(""));
        }
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(setCurrentView(""));
        }
    }, []);

    useEffect(() => () => {
        updateBackgroundColour("#383838")
    }, [])

    if(userInfo.userName === null){
        return <CircularProgress/>
    }

    return (<Box className={"centre-box"}>
        <Box className={"inner-box"}>
            <LogoutPanel/>
            <Typography fontSize={"16px"} variant="h6" component="h2" textTransform={"uppercase"} marginY={1}>
                Account Settings
            </Typography>
            <p>Change your account settings here</p>
            <Stack className={"list-card-container"} justifyContent={"space-between"} direction={"row"}>
                <Typography>Display Name:</Typography>
                <Typography>{userInfo.userName}</Typography>
            </Stack>
            <ChangePassword/>
            <DisplayUID userInfo={userInfo}/>
            <DisplayStorageUsed storageUsedInBytes={userInfo.storageUsedInBytes} availableStorage={userInfo.allocatedStorageGBs}/>
            <DisplayAccountType isPro={userInfo.isPro} userTier={userInfo.userTier}/>
            <DataCollectionToggle dataCollectionConsent={userInfo.hasDataCollectionConsent}/>
            <DisplayRetinizeDebug userInfo={userInfo}/>
            {/*<DeleteAccount/>*/}
        </Box>
    </Box>)
}