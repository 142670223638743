import {Box} from "@mui/material";
import React, {useEffect} from "react";
import GettingStarted from "./ContentPages/GettingsStarted";
import {updateBackgroundColour} from "../functions/UniversalJavascriptFunctions";

export default function LandingPageV2() {

    useEffect(() =>{
        updateBackgroundColour("#7F7F7F")
    })

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Box width={"100%"} overflow={"auto"}>
            </Box>
            <Box sx={{maxWidth: {xs: "90vw", sm: "100%", md: "60vw"}}}>
                <GettingStarted/>
            </Box>
        </Box>

    )
}