import {Checkbox} from "@mui/material";
import {blue} from "@mui/material/colors";
import React from "react";

export const CheckboxSelection = ({name, defaultValue, disabled}) => {

    const [checked, setChecked] = React.useState(defaultValue);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return <Checkbox
        onClick={handleChange}
        disabled={disabled}
        checked={checked}
        name={name}
        sx={{
            height: 40,
            color: blue[800],

            '&.Mui-checked': {
                color: blue[600],
            },
        }}
        />
}